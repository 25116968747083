<template>
    <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on, attrs }">
        <v-btn :style="style" outlined color="#0000001f" depressed class="manage" v-bind="attrs" v-on="on">
            <img src="~@/assets/icon.png">
        </v-btn>
        </template>
        <v-card color="#222">
            <v-list color="#222">
                <!-- <div class="menu-action">
                    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="ma-2" outlined color="rgba(17,17,17)">Gerenciar conta</v-btn>
                        </template>
                        <ManageAccount @close="dialog = false" />
                    </v-dialog>
                </div> -->

                <div class="menu-action">
                <v-btn @click.prevent="logout()" class="ma-2" outlined color="error">
                    Sair
                </v-btn>
                </div>
            </v-list>
        </v-card>
    </v-menu>
</template>
  
<script>
    // import ManageAccount from './ManageAccount.vue';

    export default {
        name: 'DesktopSelector',
        props: {
            systems: {
                type: Array
            }
        },
        components: {
            // ManageAccount
        },
        data: () => ({
            style: {
                'display': 'none'
            },
            dialog: false
        }),
        methods: {
            logout() {
                localStorage.removeItem('accessToken');
                this.$store.commit('setAuthentication', false);
                this.$router.push('/login');
            }
        },
        mounted() {
            if(screen.width > 800) {
                this.style['display'] = 'initial';
            }
        }
    }
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/aluno-aula'
  },
  {
    path: '/aluno-aula',
    name: 'AlunoAula',
    component: () => import('@/views/reports/AlunoAula.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/aluno-modulo',
    name: 'AlunoModulo',
    component: () => import('@/views/reports/AlunoModulo.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/aluno-lista-de-aulas',
    name: 'AlunoListaDeAulas',
    component: () => import('@/views/reports/AlunoListaDeAulas.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      const isAuthenticated = !!localStorage.getItem('accessToken');

      if (isAuthenticated) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode:'history',
  routes
})

router.beforeEach((to, from, next) => {
  const user_role = store.state.user_role;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('accessToken')) {
      next({ path: '/login' });
    } else {
      if (to.meta.requiredRole && user_role !== to.meta.requiredRole) {
        return next('/unauthorized');
      }

      next();
    }
  } else {
    next();
  }
});

export default router

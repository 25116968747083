<template>
    <v-navigation-drawer v-model="show" color="#222" fixed :permanent="permanent" :expand-on-hover="permanent">
        <v-list>
            <v-list-item class="px-2">
            <v-list-item-avatar color="rgb(146, 11, 185)">
                <!-- <v-img src="https://www.unify.eng.br/mail/gustavo_assinatura.png"></v-img> -->
                <span class="white--text text-h6">{{ $store.state.user_name[0] }}</span>
            </v-list-item-avatar>
            <v-list-item-content class="user-infos">
                <v-list-item-title class="user-name">
                <!-- Gustavo Borges -->
                {{ $store.state.user_name }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle>gustavo@unify.eng.br</v-list-item-subtitle> -->
                <v-list-item-subtitle>{{ $store.state.user_mail }}</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list nav dense>
            <router-link v-for="(item, i) in items" :key="i" :to="item.link">
                <v-list-item link :class="item.class">
                    <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
            </router-link>
        </v-list>
    </v-navigation-drawer>
</template>
  
<script>
    export default {
        name: 'DesktopMenu',
        props: {
            items: {
                type: Array
            },
            drawer: {
                type: Boolean
            }
        },
        data: () => ({
            permanent: null,
            show:null
        }),
        watch: {
            drawer: {
                handler() {
                    this.show = this.drawer;
                }
            }
        },
        mounted() {
            this.permanent = (screen.width > 800);
        }
    }
</script>
<template>
  <v-app>
    <v-main :class="{ 'report': isReport, 'control': isControl }">
      <DesktopSelector v-if="isAuthenticated" />
      <DesktopMenu v-if="isAuthenticated" :drawer="drawer" :items="items" />
      <MobileMenu v-if="isAuthenticated" @drawer="receiveDrawer" />
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import DesktopSelector from '@/components/default/DesktopSelector.vue'
import DesktopMenu from '@/components/default/DesktopMenu.vue'
import MobileMenu from '@/components/default/MobileMenu.vue'

//tremors: car-brake-alert

export default {
  name: 'App',
  components: {
    DesktopSelector,
    DesktopMenu,
    MobileMenu
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    isReport() {
      return (this.$store.state.system == 'report');
    },
    isControl() {
      return (this.$store.state.system == 'control');
    },
  },
  data: () => ({
    items: [
      {
        icon: 'mdi-clipboard-edit-outline',
        text: 'Relatório #1',
        link: '/aluno-aula',
        class: "edit-report"
      },
      {
        icon: 'mdi-clipboard-edit-outline',
        text: 'Relatório #2',
        link: '/aluno-modulo',
        class: "edit-report"
      },
      {
        icon: 'mdi-clipboard-edit-outline',
        text: 'Relatório #3',
        link: '/aluno-lista-de-aulas',
        class: "edit-report"
      },
    ],
    drawer:true
  }),
  methods: {
    receiveDrawer() {
      this.drawer = true;
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
  @import '@/styles/default.scss';
  @import '@/styles/menu.scss';

  .mobile-menu-logo {
    max-height:50%;
  }

  .report {
    .v-navigation-drawer {
      .alarms,
      .cold,
      .energy,
      .gas,
      .hot,
      .power,
      .rooms,
      .water {
        display:flex;
      }

      .hvac,
      .lights {
        display:none;
      }
    }
  }

  .control {
    .v-navigation-drawer {
      .alarms,
      .cold,
      .energy,
      .gas,
      .hot,
      .power,
      .rooms,
      .water {
        display:none;
      }

      .hvac,
      .lights {
        display:flex;
      }
    }
  }
</style>